<script setup lang="ts">
interface IHeaderProps {
  title?: string;
  subtitle?: string;
  variant?: 'gradient' | 'solid';
}

withDefaults(defineProps<IHeaderProps>(), { variant: 'gradient' });
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <header
    v-bind="$attrs"
    :class="[
      subtitle ? 'h-[60px]' : 'h-[52px]',
      'flex items-center justify-between pl-15 pr-10',
      {
        'bg-primary-alt': variant === 'solid',
        'bg-gradient-to-b from-primary-alt to-primary-alt-light': variant === 'gradient',
      },
    ]"
  >
    <div class="flex items-center">
      <slot name="prefix" />

      <slot name="title">
        <div class="flex flex-col justify-center text-white">
          <div :class="[subtitle ? 'text-base' : 'text-medium', 'font-semibold line-clamp-1']">
            {{ title }}
          </div>

          <slot
            v-if="subtitle"
            name="subtitle"
          >
            <div class="text-small mt-5">
              {{ subtitle }}
            </div>
          </slot>
        </div>
      </slot>
    </div>

    <slot name="postfix" />
  </header>
</template>
